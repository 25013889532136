import config from "../config"
import apiFunction, {
  getAccessToken,
  getBusinessIdFromLocalStorage,
} from "./ApiServices"
import qs from "qs"
import axios from "axios"
import { captureError } from "../Utils/helpers"
import LabelMaker from "../schema/labelMaker"
import { epochToDate } from "../Utils/dateTime"
import JSZip from "jszip"
import { getFileFomS3 } from "./fileUpload"

export const getAccessTokenV2 = async () => {
  return window.localStorage.getItem("Access_Token")
}

export async function getCommonHeaders() {
  const accessToken = await getAccessTokenV2()
  return {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  }
}

export async function createRecipev2(data) {
  const accessToken = await getAccessTokenV2()

  const res = await fetch(`${config.APIENDPOINT}/api/user-recipes`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  })
  const response = await res.json()

  return response
}

export async function updateRecipev2(id, data) {
  const accessToken = await getAccessTokenV2()

  const res = await fetch(`${config.APIENDPOINT}/api/user-recipes/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  })
  const response = await res.json()

  return response
}

export async function getRecipesv2(params) {
  const res = await fetch(
    `${config.APIENDPOINT}/api/user-recipes?${qs.stringify(params)}`,
    {
      method: "GET",
      headers: await getCommonHeaders(),
    },
  )
  let response = await res.json()
  return response
}

export async function getRecipev2(recipeId) {
  let accessToken = await getAccessTokenV2()

  let res = await fetch(`${config.APIENDPOINT}/api/user-recipes/${recipeId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  let response = await res.json()

  return response.result ?? {}
}

export async function getBaseRecipesv2(payload) {
  const accessToken = await getAccessTokenV2()

  const res = await fetch(
    `${config.APIENDPOINT}/api/user-recipes?type=BASE_RECIPE&${qs.stringify(
      payload,
    )}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  )
  const response = await res.json()

  return response
}

export async function mergeRecipesv2(recipeData) {
  const oldRecipes = await apiFunction.getUserRecipeDetails(recipeData)
  const data = await getRecipesv2()
  const newRecipes = data.result ?? []
  const trasnformedRecipes = newRecipes.map((v) => ({
    ...v,
    itemDetails: v,
  }))

  return [...trasnformedRecipes, oldRecipes]
}

export function transformRecipev2(recipes, brandNames) {
  return recipes.map((v) => ({
    id: v.recipe_id,
    brandId: v.brandId,
    brandName: brandNames[v.brandId],
    name: v.name,
    details: v,
    status: v.status,
    percentage: 80,
    date: v.modifiedAt || v.updatedAt || v.createdAt || "",
    mandatoryDeclarations: v,
  }))
}

export function transformRecipeV3(recipes, brandNames) {
  return recipes.map((v) => ({
    id: v.recipe_id,
    brandId: v.brand_id,
    brandName: brandNames[v.brand_id],
    name: v.name,
    details: v,
    status: v.status,
    percentage: 80,
    date: v.modified_at || "",
    mandatoryDeclarations: v,
    recipe_code: v.recipe_code || "",
  }))
}

export async function fetchReviewRecipes(params) {
  const accessToken = await getAccessTokenV2()

  const res = await fetch(`${config.APIENDPOINT}/api/user-recipes?${params}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  const response = await res.json()

  return response
}

export async function createDuplicateRecipev2(data) {
  const accessToken = await getAccessTokenV2()

  const res = await fetch(
    `${config.APIENDPOINT}/api/user-recipes/create-duplicate-recipe`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    },
  )
  const response = await res.json()

  return response
}

export async function bulkDownloadRecipev2(businessId, companyName, recipeIds) {
  const accessToken = await getAccessTokenV2()

  let res = await fetch(
    `${config.APIENDPOINT}/api/user-recipes/bulk-download`,
    {
      method: "POST",
      body: JSON.stringify({
        businessId: businessId,
        companyName: companyName,
        recipeIds: recipeIds,
      }),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    },
  )
  const response = await res.blob()

  const fileURL = window.URL.createObjectURL(response)
  var a = document.createElement("a")
  a.href = fileURL
  a.download = `${companyName}.xlsx`
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export async function createIngredientv2(data) {
  const accessToken = await getAccessTokenV2()

  const res = await fetch(`${config.APIENDPOINT}/api/user-ingredients`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  })
  const response = await res.json()

  return response
}

export async function getIngredientsv2(data) {
  const accessToken = await getAccessTokenV2()

  const res = await fetch(
    `${config.APIENDPOINT}/api/user-ingredients?${qs.stringify(data)}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  )
  const response = await res.json()

  return response
}

export async function getIngredientv2(id) {
  const accessToken = await getAccessTokenV2()

  const res = await fetch(`${config.APIENDPOINT}/api/user-ingredients/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  const response = await res.json()

  return response
}

export async function updateIngredientv2(id, data) {
  const res = await fetch(`${config.APIENDPOINT}/api/user-ingredients/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: await getCommonHeaders(),
  })
  const response = await res.json()

  return response
}

export async function searchIngredientsv2(keyword, businessId) {
  const accessToken = await getAccessTokenV2()

  const res = await fetch(
    `${config.APIENDPOINT}/api/user-recipes/search/${keyword}?businessId=${businessId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  )
  const response = await res.json()

  return response.data ?? {}
}

export async function createNewRecipev2(data) {
  const res = await fetch(`${config.APIENDPOINT}/api/v2/recipes`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: await getCommonHeaders(),
  })
  const response = await res.json()

  return response ?? {}
}

export async function createNewIngredientv2(data) {
  data.contains = data?.contains?.contains
  data.may_contain = data?.may_contain?.may_contain
  const res = await fetch(`${config.APIENDPOINT}/api/v2/ingredients`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: await getCommonHeaders(),
  })
  const response = await res.json()
  return response ?? {}
}

export async function searchIngredientv2(payload) {
  const res = await fetch(
    `${config.APIENDPOINT}/api/v2/ingredients/search?${qs.stringify(payload)}`,
    {
      method: "GET",
      headers: await getCommonHeaders(),
    },
  )
  const response = await res.json()
  return response?.data ?? {}
}

export async function deleteBaseRecipe(recipeId) {
  const res = await fetch(
    `${config.APIENDPOINT}/api/v2/recipes/${recipeId}?type=BASE_RECIPE`,
    {
      method: "DELETE",
      headers: await getCommonHeaders(),
    },
  )
  const response = await res.json()
  return response ?? {}
}

export async function deleteIngredient(ingredientId) {
  const res = await fetch(
    `${config.APIENDPOINT}/api/v2/ingredients/${ingredientId}`,
    {
      method: "DELETE",
      headers: await getCommonHeaders(),
    },
  )
  const response = await res.json()
  return response ?? {}
}

export async function getRecipesV3(payload) {
  const res = await fetch(
    `${config.APIENDPOINT}/api/v2/recipes?${qs.stringify(payload, {
      arrayFormat: "indices",
      encode: false,
    })}`,
    {
      method: "GET",
      headers: await getCommonHeaders(),
    },
  )
  const response = await res.json()

  return response
}

export async function getBaseRecipesV3(payload) {
  const res = await fetch(
    `${config.APIENDPOINT}/api/v2/recipes?type=BASE_RECIPE&${qs.stringify(
      payload,
    )}`,
    {
      method: "GET",
      headers: await getCommonHeaders(),
    },
  )
  const response = await res.json()
  // This is temporary Fix Filtred record should be come from backend

  return response
}

export async function getIngredientsV3(payload) {
  const res = await fetch(
    `${config.APIENDPOINT}/api/v2/ingredients?${qs.stringify(payload)}`,
    {
      method: "GET",
      headers: await getCommonHeaders(),
    },
  )
  const response = await res.json()

  return response
}

export async function createDuplicateRecipeV3(payload) {
  const res = await fetch(
    `${config.APIENDPOINT}/api/v2/recipes/${payload.recipeId}/duplicate`,
    {
      method: "PUT",
      headers: await getCommonHeaders(),
      body: JSON.stringify(payload),
    },
  )
  const response = await res.json()

  return response
}

export async function getRecipeV3(recipeId) {
  const res = await fetch(`${config.APIENDPOINT}/api/v2/recipes/${recipeId}`, {
    method: "GET",
    headers: await getCommonHeaders(),
  })
  const response = await res.json()

  return response
}

export async function getBaseRecipeV3(recipeId) {
  const res = await fetch(
    `${config.APIENDPOINT}/api/v2/recipes/${recipeId}?type=BASE_RECIPE`,
    {
      method: "GET",
      headers: await getCommonHeaders(),
    },
  )
  const response = await res.json()

  return response
}

export async function getIngredientV3(ingId) {
  const res = await fetch(`${config.APIENDPOINT}/api/v2/ingredients/${ingId}`, {
    method: "GET",
    headers: await getCommonHeaders(),
  })
  const response = await res.json()

  return response
}

export async function updateRecipeV3(payload) {
  const res = await fetch(
    `${config.APIENDPOINT}/api/v2/recipes/${payload.id}`,
    {
      method: "PATCH",
      body: JSON.stringify(payload.data),
      headers: await getCommonHeaders(),
    },
  )
  const response = await res.json()

  return response
}

export async function updateBaseRecipeV3(payload) {
  const res = await fetch(
    `${config.APIENDPOINT}/api/v2/recipes/${payload.id}?type=BASE_RECIPE`,
    {
      method: "PATCH",
      body: JSON.stringify(payload.data),
      headers: await getCommonHeaders(),
    },
  )
  const response = await res.json()

  return response
}

export async function updateIngredientV3(payload) {
  payload.data.contains =
    (payload &&
      payload.data &&
      payload.data.contains &&
      payload.data.contains.selected) ||
    (payload &&
      payload.data &&
      payload.data.contains &&
      payload.data.contains.contains)
  ;("")
  payload.data.may_contain =
    (payload &&
      payload.data &&
      payload.data.may_contain &&
      payload.data.may_contain.selected) ||
    (payload &&
      payload.data &&
      payload.data.may_contain &&
      payload.data.may_contain.may_contain)
  ;("")

  const res = await fetch(
    `${config.APIENDPOINT}/api/v2/ingredients/${payload.id}`,
    {
      method: "PATCH",
      body: JSON.stringify(payload.data),
      headers: await getCommonHeaders(),
    },
  )
  const response = await res.json()

  return response
}
export async function requestIngredientv2(payload) {
  const res = await fetch(`${config.APIENDPOINT}/api/v2/ingredients/request`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: await getCommonHeaders(),
  })
  const response = await res.json()
  return response ?? {}
}
// ============================= IAM =============================

export async function generatePartnerApi(business_id) {
  const res = await fetch(
    `${config.APIENDPOINT}/api/v2/iam?business_id=${business_id}`,
    {
      method: "POST",
      headers: await getCommonHeaders(),
    },
  )
  const response = await res.json()
  return response ?? {}
}

export async function getPartnerApis(business_id) {
  const res = await fetch(
    `${config.APIENDPOINT}/api/v2/iam?business_id=${business_id}`,
    {
      method: "GET",
      headers: await getCommonHeaders(),
    },
  )
  const response = await res.json()
  return response ?? {}
}

export async function regenerateSecret(api_key) {
  const res = await fetch(
    `${config.APIENDPOINT}/api/v2/iam/regenerate/${api_key}`,
    {
      method: "PATCH",
      headers: await getCommonHeaders(),
    },
  )
  const response = await res.json()
  return response ?? {}
}

export async function bulkUploadIngredient(ingredients) {
  const res = await fetch(`${config.APIENDPOINT}/api/v2/ingredients/bulk`, {
    method: "POST",
    body: JSON.stringify(ingredients),
    headers: await getCommonHeaders(),
  })
  const response = await res.json()
  return response ?? {}
}

// ============================= ADDITIVES =============================

function additivesFilter(data, category, food_name) {
  switch (food_name.toLowerCase()) {
    case "whole wheat flour (atta)":
    case "durum wheat atta":
    case "multigrain atta":
    case "atta":
      return []
    case "corn flour":
      if (category !== "6.2.1") return {}
      return data.filter((v) => {
        return ["1101(i)", "1204", "220", "221", "222", "223", "224"].includes(
          v.ins,
        )
      })
    case "refined wheat (maida)":
    case "maida":
      return data.filter((v) => {
        return ["225", "227"].includes(v.ins)
      })
    default:
      return data ?? []
  }
}

export async function insMappingByClass(
  additiveClass,
  category,
  food_name = "",
  country,
) {
  const res = await fetch(
    `${config.APIENDPOINT}/api/v2/additives?className=${additiveClass}&category=${category}&country=${country}`,
    {
      method: "GET",
      headers: await getCommonHeaders(),
    },
  )
  const response = await res.json()

  return additivesFilter(response, category, food_name)
}

export async function getAdditivesByCategory(
  category,
  food_name = "",
  country,
) {
  const res = await fetch(
    `${config.APIENDPOINT}/api/v2/additives?category=${category}&country=${country}`,
    {
      method: "GET",
      headers: await getCommonHeaders(),
    },
  )
  const response = await res.json()

  return additivesFilter(response, category, food_name)
}

// ========================== METEROLOGY ==========================
export async function createMeterology(payload) {
  try {
    if (payload.label_height === "") {
      payload.label_height = 0
    }
    if (payload.label_width === "") {
      payload.label_width = 0
    }
    if (payload.pack_height === "") {
      payload.pack_height = 0
    }
    if (payload.pack_width === "") {
      payload.pack_width = 0
    }
    const headers = await getCommonHeaders()
    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/meterology`,
      payload,
      { headers },
    )

    return response
  } catch (error) {
    captureError(error)
    return error.response
  }
}

export async function getLabelMetrology(labelId, country) {
  try {
    const headers = await getCommonHeaders()
    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/meterology?label_id=${labelId}&country=${country}`,
      { headers },
    )
    return response
  } catch (error) {
    captureError(error)
    return error.response
  }
}

// ============================= PDF SERVICE ========================

export async function generatePdf(
  labelName,
  labelData,
  userAddress,
  width,
  height,
  isHorizontal,
  isPrintLabel = false,
) {
  const res = await fetch(`${config.APIENDPOINT}/api/v2/label/pdf`, {
    method: "POST",
    body: JSON.stringify({
      labelData,
      userAddress,
      width,
      height,
      isHorizontal,
      isPrintLabel,
    }),
    headers: await getCommonHeaders(),
  })
  const response = await res.blob()

  const blobData = new Blob([response], { type: "application/pdf" })
  const url = window.URL.createObjectURL(blobData)
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", `${labelName}.pdf`)
  document.body.appendChild(link)
  link.click()
}

export async function generateDomescticLabelPdf(
  labelName,
  labelData,
  userAddress,
  width,
  height,
  isHorizontal,
  isPrintLabel = false,
) {
  const res = await fetch(
    `${config.APIENDPOINT}/api/v2/label/standard-size-pdf`,
    {
      method: "POST",
      body: JSON.stringify({
        labelData,
        userAddress,
        width,
        height,
        isHorizontal,
        isPrintLabel,
      }),
      headers: await getCommonHeaders(),
    },
  )
  const response = await res.blob()

  const blobData = new Blob([response], { type: "application/pdf" })
  const url = window.URL.createObjectURL(blobData)
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", `${labelName}.pdf`)
  document.body.appendChild(link)
  link.click()
}

async function generateInvoicePdf(
  labelName,
  labelData,
  userAddress,
  width,
  height,
  isHorizontal,
  isPrintLabel = false,
) {
  const res = await fetch(`${config.APIENDPOINT}/api/v2/label/pdf`, {
    method: "POST",
    body: JSON.stringify({
      labelData,
      userAddress,
      width,
      height,
      isHorizontal,
      isPrintLabel,
    }),
    headers: await getCommonHeaders(),
  })

  return await res.blob()
}

export async function generateZip(
  labelName,
  labelData,
  userAddress,
  width,
  height,
  isHorizontal,
  isPrintLabel = false,
) {
  try {
    const zip = new JSZip()

    const invoicePdfBlob = await generateInvoicePdf(
      labelName,
      labelData,
      userAddress,
      width,
      height,
      isHorizontal,
      isPrintLabel,
    )

    zip.file(`${labelName}_(Label).pdf`, invoicePdfBlob, { binary: true })

    if (labelData?.label_json?.artwork_file) {
      let artwork_file

      if (labelData.label_json.artwork_file.includes("/public/")) {
        artwork_file = await fetch(labelData.label_json.artwork_file)
      } else {
        const artwork_url = await getFileFomS3(
          labelData.label_json.artwork_file,
        )
        artwork_file = await fetch(artwork_url.data)
      }

      if (artwork_file.ok) {
        const awsS3Blob = await artwork_file.blob()
        zip.file(`${labelName}_(Artwork).pdf`, awsS3Blob, { binary: true })
      } else {
        console.error("Failed to fetch AWS S3 PDF")
      }
    }

    const zipBlob = await zip.generateAsync({ type: "blob" })

    const zipUrl = window.URL.createObjectURL(zipBlob)

    const link = document.createElement("a")
    link.href = zipUrl
    link.setAttribute("download", `${labelName}.zip`)
    document.body.appendChild(link)
    link.click()

    window.URL.revokeObjectURL(zipUrl)
  } catch (error) {
    console.error("Error generating ZIP file", error)
  }
}

export async function generateML(mlData) {
  try {
    const res = await fetch(
      `${config.APIENDPOINT}/api/v2/menu-label/generate-files`,
      {
        method: "POST",
        body: JSON.stringify({
          mlData,
        }),
        headers: await getCommonHeaders(),
      },
    )
    const response = await res.blob()

    const blobData = new Blob([response], { type: "application/pdf" })
    const url = window.URL.createObjectURL(blobData)
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", `${mlData.recipeDetails.recipe_name}.pdf`)
    document.body.appendChild(link)
    link.click()
  } catch (error) {
    throw error
  }
}

// ============================= FOODS ========================

export async function getFoods(category) {
  const res = await fetch(
    `${config.APIENDPOINT}/api/v2/foods?category=${category}`,
    {
      method: "GET",
      headers: await getCommonHeaders(),
    },
  )
  const response = await res.json()
  return response ?? {}
}

export async function deleteRecipe(recipeId) {
  const res = await fetch(
    `${config.APIENDPOINT}/api/user-recipes/${recipeId}`,
    {
      method: "DELETE",
      headers: await getCommonHeaders(),
    },
  )
  const response = await res.json()
  return response ?? {}
}

export async function deleteUserIngredient(ingId) {
  const res = await fetch(
    `${config.APIENDPOINT}/api/user-ingredients/${ingId}`,
    {
      method: "DELETE",
      headers: await getCommonHeaders(),
    },
  )
  const response = await res.json()
  return response ?? {}
}

// ======================= Permissions =====================
export async function inviteUserV2(user_details) {
  const headers = await getCommonHeaders()
  const user_invite_details = {
    email: user_details.emailId,
    business_id: user_details.businessId,
    brandIds: user_details.brandIds,
    role: user_details.permission,
    name: user_details.name,
    phone_number: user_details.phoneNumber,
    invited_by: user_details.invitedBy,
    business_name: user_details.business_name,
  }
  try {
    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/permissions/invite`,
      user_invite_details,
      { headers },
    )
    return response
  } catch (error) {
    captureError(error)
    throw error.response
  }
}

export async function getPermissions(businessId) {
  const response = await fetch(
    `${config.APIENDPOINT}/api/v2/permissions/${businessId}/list`,
  )
  const data = await response.json()

  return data
}

export async function createLabelV2(label_data) {
  const headers = await getCommonHeaders()

  const response = await fetch(`${config.APIENDPOINT}/api/v2/label`, {
    method: "POST",
    headers: {
      ...headers,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(label_data),
  })

  return await response.json()
}
export async function updateLabelV2(label_data) {
  try {
    const headers = await getCommonHeaders()
    const response = await fetch(
      `${config.APIENDPOINT}/api/v2/label/${label_data.id}`,
      {
        method: "PUT",
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(label_data),
      },
    )
    return response.json()
  } catch (error) {
    captureError(error)
    throw error
  }
}

export async function getLabelByIdV2(label_id) {
  try {
    const headers = await getCommonHeaders()
    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/label/${label_id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    captureError(error)
    throw error
  }
}

export async function getAllLabelsByBusinessIdV2(business_id) {
  try {
    const headers = await getCommonHeaders()
    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/label/all/${business_id}`,
      { headers },
    )
    return response.data
  } catch (error) {
    captureError(error)
    throw error
  }
}

export async function getAllReviewByVersionNumber(label_id, version) {
  try {
    const headers = await getCommonHeaders()
    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/review/label_id/${label_id}/${version}`,
      { headers },
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function deleteLabelV2(label_id) {
  try {
    const headers = await getCommonHeaders()
    const response = await axios.delete(
      `${config.APIENDPOINT}/api/v2/label/${label_id}`,
      { headers },
    )

    return response
  } catch (error) {
    captureError(error)
    throw error
  }
}

const labelMakerSchema = LabelMaker({}, {})

export async function getFilteredLabels(businessId) {
  const labelsResponse = await getAllLabelsByBusinessIdV2(businessId)
  const filteredLabels = labelsResponse.data
    .filter((label) => label?.label_json)
    .map((label) => {
      const labelName = label?.title
      const currentStep = parseInt(label?.label_json?.currentStep) || 0
      const isCompleted = label?.label_json?.isLabelComplete
      return {
        name: label?.title,
        category: `${label?.label_json?.category?.category} ${label?.label_json?.category?.description}`,
        id: label?.id,
        status: isCompleted ? "Completed" : "Pending",
        updatedAt: epochToDate(label?.updatedAt),
        businessId: label?.business_id,
        details: label,
        createdAt: label?.label_json?.created_on,
      }
    })

  return filteredLabels
}

///////////////////////////// Access Controls ////////////////////////

export async function createUserRole(user_details) {
  // try {
  // const headers = await getCommonHeaders()

  const response = await axios.post(
    `${config.APIENDPOINT}/api/v2/access-controls`,
    user_details,
    { headers: { "Content-Type": "application/json" } },
  )
  return response.data
  // } catch (error) {
  //   console.error("create user role Error: ", error)
  // }
}

export async function getUserRole(business_id, lbUserId) {
  try {
    const headers = await getCommonHeaders()
    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/access-controls/${business_id}/${lbUserId}`,
      { headers },
    )
    return response.data
  } catch (e) {
    console.error("get user role error: ", e)
  }
}

export async function removeUserRole(business_id, lbUserId, current_user_id) {
  const headers = await getCommonHeaders()
  const updatedBy = current_user_id

  try {
    const response = await axios.delete(
      `${config.APIENDPOINT}/api/v2/access-controls/remove/${business_id}/${lbUserId}`,
      {
        headers,
        data: updatedBy,
      },
    )

    return response.data
  } catch (error) {
    return error
  }
}

export async function getAllUsersRoleByBusinessId(business_id) {
  try {
    const headers = await getCommonHeaders()
    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/access-controls/${business_id}/all`,
      { headers },
    )
    return response.data
  } catch (error) {
    console.error("got error while roles by business id ", error)
  }
}

export async function UpdateUserRole(user_details) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.put(
      `${config.APIENDPOINT}/api/v2/access-controls/${user_details.businessId}/${user_details.lbUserId}`,
      user_details,
      { headers },
    )
    return response.data
  } catch (error) {
    return error
    // console.error("create user role Error: ", error)
  }
}

//////////////////////////////////////////////////////////////////////////
export async function advertiseBusiness(user_details) {
  try {
    const pdfEndpoint = `${config.APIENDPOINT}/api/request-demo`

    const response = await axios.post(pdfEndpoint, user_details)

    // const url = window.URL.createObjectURL(new Blob([response.data]))
    // const link = document.createElement("a")
    // link.href = url
    // link.setAttribute("download", "free-ebook-compliant-food-label.pdf")
    // document.body.appendChild(link)
    // link.click()
    // document.body.removeChild(link)

    return response
  } catch (error) {
    captureError(error)
    throw error
  }
}

export async function NeutraceuticalsReportDownload(user_details) {
  try {
    const pdfEndpoint = `${config.APIENDPOINT}/api/nutraceuticals`

    const response = await axios.post(pdfEndpoint, user_details)

    return response.data
  } catch (error) {
    captureError(error)
    throw error
  }
}

///////////////////////////////////// User Login / Signup /////////////////////

export async function getUserDetails(user_id) {
  try {
    // const headers = await getCommonHeaders()
    const res = await axios.get(
      `${config.APIENDPOINT}/api/v2/user/${user_id}`,
      // {
      //   headers,
      // },
    )
    const response = res.json()
    return response
  } catch (error) {
    console.error("got error while getting user details by id ", error)
  }
}

export async function getUserDetailsFromToken() {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(`${config.APIENDPOINT}/api/v2/login`, {
      headers,
    })

    return response.data
  } catch (error) {
    console.error("got error while getting user details by id ", error)
  }
}

export async function sendOtp(phone_number) {
  try {
    const res = await axios.post(`${config.APIENDPOINT}/api/v2/login/sendOtp`, {
      phone_number: phone_number,
    })

    return res.data
  } catch (error) {
    return error
  }
}

export async function verifyOtp(phone_number, otp) {
  try {
    const res = await axios.post(
      `${config.APIENDPOINT}/api/v2/login/verifyOtp`,
      {
        phone_number: phone_number,
        otp: otp,
      },
    )

    const response = await res.json()
    return response
  } catch (error) {}
}

export async function loginV2(email, password) {
  // try {
  const response = await fetch(`${config.APIENDPOINT}/api/v2/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: email, password: password }),
  })

  return await response.json()

  // } catch (error) {
  //   return error
  // }
}

export async function loginWithPhoneNumberAndOtp(phone_number, otp) {
  const data = { phone_number, otp }

  const res = await fetch(`${config.APIENDPOINT}/api/v2/login`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  })

  const response = await res.json()

  return response
}

export async function signupNewUser(user_details) {
  const res = await fetch(`${config.APIENDPOINT}/api/v2/signup`, {
    method: "POST",
    body: JSON.stringify(user_details),
    headers: {
      "Content-Type": "application/json",
    },
  })

  const response = await res.json()

  return response
}

export async function forgetPassword(email) {
  const response = axios.post(
    `${config.APIENDPOINT}/api/v2/user/resetLink`,
    { email, url: "https://www.foodlabelsolutions.com/forgot-password" },
    { headers: { "Content-Type": "application/json" } },
  )

  return response
}

export async function resetPassword(newPassword, userId) {
  const header = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  const response = await axios.put(
    `${config.APIENDPOINT}/api/v2/user/password/${userId}`,
    { newPassword },
    { headers: { "Content-Type": "application/json" } },
  )
  return response
}

export async function updateUserDetails(user_Details) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.put(
      `${config.APIENDPOINT}/api/v2/user/user-details/${user_Details.lbUserId}`,
      user_Details,
      {
        headers,
      },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function updateUserEmailSendOtp(user_Details) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/user/resetEmail`,
      user_Details,
      {
        headers,
      },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function updateUserEmailVerifyOtp(user_Details) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/user/resetEmail/verifyOtp`,
      user_Details,
      {
        headers,
      },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function updateUserPhoneNumberSendOtp(user_Details) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/user/resetPhoneNumber`,
      user_Details,
      {
        headers,
      },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function updateUserPhoneNumberVerifyOtp(user_Details) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/user/resetPhoneNumber/verifyOtp`,
      user_Details,
      {
        headers,
      },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

///////////////////////////////////// Business ///////////////////////////////

export async function createBusiness(business_details) {
  try {
    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/business`,
      business_details,
      { headers: { "Content-Type": "application/json" } },
    )

    return response.data
  } catch (error) {
    return error
  }
}

export async function getBusinessById(business_id) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/business/${business_id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error
  }
}

export async function getUsersBusiness() {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(`${config.APIENDPOINT}/api/v2/business`, {
      headers,
    })

    return response.data
  } catch (error) {
    return error
  }
}

export async function updateBusiness(business_details) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.put(
      `${config.APIENDPOINT}/api/v2/business/${business_details.business_id}`,
      business_details,
      {
        headers,
      },
    )

    return response.data
  } catch (error) {
    return error
  }
}

////////////////////////////////// Brands /////////////////////////////////

export async function createBrand(brands_details) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/brand`,
      brands_details,
      { headers },
    )

    return response.data
  } catch (error) {
    return error
  }
}

export async function updateBrand(brands_details) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.put(
      `${config.APIENDPOINT}/api/v2/brand/${brands_details.id}`,
      brands_details,
      { headers },
    )

    return response.data
  } catch (error) {
    return error
  }
}

export async function getAllBrandsByBusinessId(business_id) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/brand/business/${business_id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error
  }
}

export async function getBrandDetailsByBusinessAndBrandId(
  business_id,
  brand_id,
) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/brand/${business_id}/${brand_id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error
  }
}

export async function deleteBrandById(business_id, brand_id) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.delete(
      `${config.APIENDPOINT}/api/v2/brand/${business_id}/${brand_id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error
  }
}

export async function getBrandDetailsById(brand_id) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/brand/${brand_id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error
  }
}

////////////////////////////////////Storage ///////////////////////////////////

///////////////////////////// Subscription ////////////////////////////////

export const checkIsSubscriptionFreeTrial = async () => {
  try {
    const business_id = getBusinessIdFromLocalStorage()
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/payment/free-trial-status?business_id=${business_id}`,
      {
        headers,
      },
    )

    return response.data
  } catch (error) {
    return error
  }
}

export const getSubscritpionDetails = async (business_id) => {
  try {
    const headers = await getCommonHeaders()

    // const business_id = getBusinessIdFromLocalStorage()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/subscriptions/${business_id}`,
      {
        headers,
      },
    )

    return response.data
  } catch (error) {
    return error
  }
}

export const initiateFreeTrialForUser = async (payload) => {
  try {
    const response = await fetch(
      `${config.APIENDPOINT}/api/v2/payment/initiate-payment`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: await getCommonHeaders(),
      },
    )

    const responseData = await response.json()

    return responseData
  } catch (error) {
    return error
  }
}

////////////////////// AI VALIDATION /////////////////

export const generateAiReport = async (form) => {
  try {
    const response = await axios(
      "https://ai-api.foodlabelsolutions.com" + "/product",
      {
        method: "POST",
        data: form,
      },
    )
    return response.data
  } catch (err) {
    return err.response.data
  }
}

export const convertToImage = async (data) => {
  try {
    const response = await axios(
      `${config.APIENDPOINT}` + "/api/v2/ai_label/pdf-to-image",
      {
        method: "POST",
        data: data,
      },
    )
    return response.data
  } catch (err) {
    return err.response.data
  }
}

export const createLabel = async (data) => {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.post(
      `${config.APIENDPOINT}` + "/api/v2/ai_label",
      data,
      {
        headers,
      },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const getLabelById = async (id) => {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}` + `/api/v2/ai_label/${id}`,
      {
        headers,
      },
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const getLabelByIdWithVersion = async (id, version) => {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}` + `/api/v2/ai_label/${id}/${version}`,
      {
        headers,
      },
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const updateAiLabel = async (data) => {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.put(
      `${config.APIENDPOINT}/api/v2/ai_label/${data.id}`,
      data,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const getLabelByBusinessId = async (business_id) => {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}` + `/api/v2/ai_label/all/${business_id}`,
      {
        headers,
      },
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const deleteLabelById = async (id) => {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.delete(
      `${config.APIENDPOINT}` + `/api/v2/ai_label/${id}`,
      {
        headers,
      },
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const getAiLabelLimitByBusinessId = async (business_id) => {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}` + `/api/v2/ai_label/label_limit/${business_id}`,
      {
        headers,
      },
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const downloadComplianceReportById = async (id) => {
  try {
    const headers = await getCommonHeaders()

    const res = await axios.post(
      `${config.APIENDPOINT}/api/v2/ai_label/download/${id}`,
      "",
      {
        headers,
        responseType: "blob",
      },
    )

    const blobData = new Blob([res.data], { type: "application/pdf" })
    const url = window.URL.createObjectURL(blobData)
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", `Compliance Report.pdf`)
    document.body.appendChild(link)
    link.click()
    window.URL.revokeObjectURL(url)
    link.remove()
  } catch (error) {
    return error
  }
}

export const downloadUtilityById = async (id) => {
  try {
    const headers = await getCommonHeaders()

    const res = await axios.post(
      `${config.APIENDPOINT}/api/v2/ai_label/download-utility/${id}`,
      "",
      {
        headers,
        responseType: "blob",
      },
    )

    const blobData = new Blob([res.data], { type: "application/pdf" })
    const url = window.URL.createObjectURL(blobData)
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", `Utility.pdf`)
    document.body.appendChild(link)
    link.click()
    window.URL.revokeObjectURL(url)
    link.remove()
  } catch (error) {
    return error
  }
}

export const fetchCategories = async () => {
  try {
    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/ai/categories`,
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const sendAiServerFailedEmail = async (payload) => {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.post(
      `${config.APIENDPOINT}` + `/api/v2/ai_label/send-email`,
      payload,
      {
        headers,
      },
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const getAllVersionArtworkOfExistingLabel = async (id) => {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}` + `/api/v2/ai_label/version/all/${id}`,
      {
        headers,
      },
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

///////////////////////// Review Label ////////////////////

export const createLabelReview = async (reviewLabelData) => {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/review`,
      reviewLabelData,
      {
        headers,
      },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getReviewLabelByLabelId(label_id) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/review/${label_id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getReviewsByVersionNumber(label_id, version) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/review/label_id/${label_id}/${version}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getLabelByLabelId(label_id) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/review/label_id/${label_id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}
export async function getReviewByReviewId(review_id) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/review/${review_id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getAllReviewLabel() {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/review/all`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getTimelineByLabelId(label_id) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/review/timeline/${label_id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getReviewDashboardDataByBusinessId(business_id) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/review/dashboard/${business_id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function uploadComment(commentData) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/comments`,
      commentData,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function updatedReviewLabel(payload) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.put(
      `${config.APIENDPOINT}/api/v2/review/${payload.review_id}`,
      payload,
      { headers },
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getReviewCommentById(commentId) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/comments/${commentId}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const deleteCommentById = async (commentId, userId) => {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.delete(
      `${config.APIENDPOINT}/api/v2/comments/${commentId}`,
      {
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
        data: { user_id: userId },
      },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const updateComment = async (commentId, userId, updatedText) => {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.put(
      `${config.APIENDPOINT}/api/v2/comments/${commentId}`,
      {
        user_id: userId,
        text: updatedText,
      },
      {
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
      },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

////////////////////////// Digital Drive ///////////////////

export async function getDigitalDriveLabelByBusinessId(business_id) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/digital-drive/dashboard/${business_id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getDigitalDriveDocumentsLabelById(label_id) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/digital-drive/${label_id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getPartialLabelDetailsByLabelId(label_id) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/digital-drive/dashboard/label/${label_id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function lockDigitalDriveDocument(document_id, password) {
  try {
    const headers = await getCommonHeaders()

    const body = {
      password: password,
    }

    const response = await axios.put(
      `${config.APIENDPOINT}/api/v2/digital-drive/lock/${document_id}`,
      body,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function unLockDigitalDriveDocument(document_id, password) {
  try {
    const headers = await getCommonHeaders()

    const body = {
      password: password,
    }

    const response = await axios.put(
      `${config.APIENDPOINT}/api/v2/digital-drive/unlock/${document_id}`,
      body,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function deleteDigitalDriveDocument(document_id, password) {
  try {
    const headers = await getCommonHeaders()
    let endpoint = `${config.APIENDPOINT}/api/v2/digital-drive/${document_id}`

    const configOptions = {
      headers: headers,
      params: {},
    }

    if (password) {
      configOptions.params.password = password
    }

    const response = await axios.delete(endpoint, configOptions)

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function uploadDigitalDriveDocument(payload) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/digital-drive`,
      payload,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function resetPasswordForDocument(document_id) {
  try {
    const headers = await getCommonHeaders()

    const body = {
      document_id: document_id,
    }

    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/digital-drive/password_reset`,
      body,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function verifyOtpForResetPasswordDocument(otp) {
  try {
    const headers = await getCommonHeaders()

    const body = {
      otp: otp,
    }

    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/digital-drive/verify_otp`,
      body,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function setNewPasswordForDocument(payload) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/digital-drive/update_password`,
      payload,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getAllVersionHistoryByLabelId(labelId) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/ai_label/version/all/${labelId}`,
      { headers },
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getReviewIdByLabelIdAndVersion(labelId, version) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/review/${labelId}/${version}`,
      { headers },
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function deleteLabelVersionByLabelId(label_id, versionNo) {
  try {
    const headers = await getCommonHeaders()

    const configOptions = {
      headers: headers,
      params: {},
    }

    let endpoint = `${config.APIENDPOINT}/api/v2/ai_label/version/${label_id}/${versionNo}`

    const response = await axios.delete(endpoint, configOptions)

    return response.data
  } catch (error) {
    return error.response.data
  }
}

//////// Consumer Care  ///////////////

export async function createConsumerCareAddress(payload) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/consumer-care`,
      payload,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getConsumerCareAddressByBusinessId(business_id) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/consumer-care/all/${business_id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

export async function getConsumerCareAddressById(id) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/consumer-care/${id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function updateConsumerCareAddressById(id) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.put(
      `${config.APIENDPOINT}/api/v2/consumer-care/${id}`,
      payload,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function deleteConsumerCareAddressById(id) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.delete(
      `${config.APIENDPOINT}/api/v2/consumer-care/${id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

///////////////storage///////////////////

export async function createStorageInstruction(payload) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/storage-instruction`,
      payload,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getStorageInstructionsByBusinessId(business_id) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/storage-instruction/all/${business_id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

export const translateText = async (text, language) => {
  const headers = await getCommonHeaders()
  try {
    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/exports-label/translate`,
      {
        text: text,
        targetLanguage: language || "fr",
      },
      { headers },
    )
    return response?.data?.data
  } catch (error) {
    console.error("Error during translation:", error)
  }
}

export const translateObject = async (text, language) => {
  const headers = await getCommonHeaders()
  try {
    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/exports-label/translate/obj`,
      {
        text: text,
        targetLanguage: language || "fr",
      },
      { headers },
    )
    return response?.data?.data
  } catch (error) {
    console.error("Error during translation:", error)
  }
}

export async function getStorageInstructionById(id) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/storage-instruction/${id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function updateStorageInstructionById(id, payload) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.put(
      `${config.APIENDPOINT}/api/v2/storage-instruction/${id}`,
      payload,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function deleteStorageInstructionById(id) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.delete(
      `${config.APIENDPOINT}/api/v2/storage-instruction/${id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getExportsLabelByBusinessId(id) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/exports-label/all/${id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}
export async function getAllExportsLabelCountByBusinessId(id) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/exports-label/all/count/${id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function deleteExportsLabelById(id) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.delete(
      `${config.APIENDPOINT}/api/v2/exports-label/${id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function createExportsLabel(payload) {
  try {
    const headers = await getCommonHeaders()
    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/exports-label`,
      payload,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getExportsLabelDashboardByBusinessId(id) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/exports-label/dashboard/${id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getExportsLabelById(id) {
  try {
    const headers = await getCommonHeaders()
    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/exports-label/${id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function updateExportsLabelById(id, payload) {
  function filterPayload(payload) {
    const unwantedFields = [
      "status",
      "createdAt",
      "updatedAt",
      "createdBy",
      "updatedBy",
      "brand_name",
    ]
    const filteredPayload = { ...payload }
    unwantedFields.forEach((field) => {
      if (filteredPayload.hasOwnProperty(field)) {
        delete filteredPayload[field]
      }
    })
    return filteredPayload
  }
  try {
    const headers = await getCommonHeaders()

    // Filter out unwanted fields from payload
    const filteredPayload = filterPayload(payload)
    filteredPayload.id = id // Set the ID in the payload

    const response = await axios.put(
      `${config.APIENDPOINT}/api/v2/exports-label/${id}`,
      filteredPayload,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response
      ? error.response.data
      : { message: "An error occurred" }
  }
}

export async function downloadExportPdf(
  labelName,
  labelData,
  userAddress,
  width,
  height,
  isHorizontal,
  isPrintLabel = false,
) {
  const res = await fetch(`${config.APIENDPOINT}/api/v2/exports-label/pdf`, {
    method: "POST",
    body: JSON.stringify({
      labelData,
      userAddress,
      width,
      height,
      isHorizontal,
      isPrintLabel,
    }),
    headers: await getCommonHeaders(),
  })
  const response = await res.blob()

  const blobData = new Blob([response], { type: "application/pdf" })
  const url = window.URL.createObjectURL(blobData)
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", `${labelName}.pdf`)
  document.body.appendChild(link)
  link.click()
}
export async function downloadMultiCountryPDF(
  labelName,
  labelData,
  userAddress,
  width,
  height,
  isHorizontal,
  isPrintLabel = false,
) {
  const res = await fetch(
    `${config.APIENDPOINT}/api/v2/exports-label/multi-country/pdf`,
    {
      method: "POST",
      body: JSON.stringify({
        labelData,
        userAddress,
        width,
        height,
        isHorizontal,
        isPrintLabel,
      }),
      headers: await getCommonHeaders(),
    },
  )
  const response = await res.blob()

  const blobData = new Blob([response], { type: "application/pdf" })
  const url = window.URL.createObjectURL(blobData)
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", `${labelName}.pdf`)
  document.body.appendChild(link)
  link.click()
}

export async function getExportsLabelByDomesticLabelId(id) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/exports-label/domestic/${id}`,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function createMultiCountryLabel(payload) {
  try {
    const headers = await getCommonHeaders()
    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/exports-label/multi-country`,
      payload,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function updateMultiCountryLabelById(id, payload) {
  try {
    const headers = await getCommonHeaders()
    const response = await axios.put(
      `${config.APIENDPOINT}/api/v2/exports-label/multi-country/${id}`,
      payload,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function getFAQ() {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(`${config.APIENDPOINT}/api/v2/faq`, {
      headers,
    })

    return response?.data
  } catch (error) {
    return error.response.data
  }
}

export async function getAllCategories() {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/categories`,
      {
        headers,
      },
    )

    return response?.data
  } catch (error) {
    return error.response.data
  }
}

export async function searchForComboMeals(payload) {
  const res = await fetch(
    `${config.APIENDPOINT}/api/v2/menu-label/search?${qs.stringify(payload)}`,
    {
      method: "GET",
      headers: await getCommonHeaders(),
    },
  )
  const response = await res.json()
  return response?.data ?? {}
}

export async function createComboMeals(data) {
  const accessToken = await getAccessTokenV2()

  const res = await fetch(
    `${config.APIENDPOINT}/api/v2/menu-label/combo-meals`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: await getCommonHeaders(),
    },
  )
  const response = await res.json()

  return response
}

export async function updateComboMeals(id, data) {
  const accessToken = await getAccessTokenV2()

  const res = await fetch(
    `${config.APIENDPOINT}/api/v2/menu-label/combo-meals/${id}`,
    {
      method: "PUT",
      body: JSON.stringify(data),
      headers: await getCommonHeaders(),
    },
  )
  const response = await res.json()

  return response
}

export async function comboMealsPdf(mlData, labelName) {
  try {
    const res = await fetch(
      `${config.APIENDPOINT}/api/v2/menu-label/generate-files/combo-meals`,
      {
        method: "POST",
        body: JSON.stringify({
          mlData,
        }),
        headers: await getCommonHeaders(),
      },
    )

    if (!res.ok) {
      return false
    } else {
      const response = await res.blob()

      const blobData = new Blob([response], { type: "application/pdf" })
      const url = window.URL.createObjectURL(blobData)
      const link = document.createElement("a")
      link.href = url
      const fileName = labelName ? `${labelName}.pdf` : "combo meal.pdf"
      link.setAttribute("download", fileName)
      document.body.appendChild(link)
      link.click()
      return true
    }
  } catch (error) {
    throw error
  }
}

export async function bulkRecipeFileUpload(payload) {
  try {
    const headers = await getCommonHeaders()
    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/bulk-recipe/upload`,
      payload,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response.data
  }
}

export async function bulkRecipeCreate(payload) {
  try {
    const headers = await getCommonHeaders()
    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/bulk-recipe/create`,
      payload,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response?.data
  }
}

export async function downloadFailedRecipeXLSX(payload) {
  try {
    const headers = await getCommonHeaders()

    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/bulk-recipe/download-failed-recipe`,
      payload,
      {
        headers,
        responseType: "blob",
      },
    )

    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })

    const url = window.URL.createObjectURL(blob)

    const link = document.createElement("a")
    link.href = url

    const content_disposition = response.headers["content-disposition"]
    let filename = "Failed Recipes.xlsx"

    if (content_disposition) {
      const file_name_match = content_disposition.match(/filename="?([^"]+)"?/)
      if (file_name_match && file_name_match.length > 1) {
        filename = file_name_match[1]
      }
    }

    link.setAttribute("download", filename)

    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  } catch (error) {
    throw error
  }
}

export async function createLabelFromRecipes(payload) {
  try {
    const headers = await getCommonHeaders()
    const response = await axios.post(
      `${config.APIENDPOINT}/api/v2/bulk-recipe/create-label`,
      payload,
      { headers },
    )

    return response.data
  } catch (error) {
    return error.response?.data
  }
}
export async function downloadBulkRecipeUploadSampleTemplate(payload) {
  try {
    const headers = await getCommonHeaders()
    const response = await axios.get(
      `${config.APIENDPOINT}/api/v2/bulk-recipe/download-bulk-template`,
      { headers },
    )
    return response?.data
  } catch (error) {
    return error.response?.data
  }
}
